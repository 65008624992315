import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';
import { func } from 'prop-types';
import IconPlus from '../IconPlus/IconPlus';

import css from './SearchFilter.module.css';

const SearchFilter = props => {
  const intl = useIntl();
  const { initialValues } = props;
  const [isOpen, setIsOpen] = useState(true);

  const searchFilterLabel = intl.formatMessage({ id: 'SearchFilterKeywords.label' });
  const searchFilterPlaceholder = intl.formatMessage({ id: 'SearchFilterKeywords.placeholder' });

  const toggleIsOpen = () => setIsOpen(!isOpen);

  return (
    <Form
      {...props}
      initialValues={initialValues(['keywords'])}
      render={({ handleSubmit, values }) => {
        return (
          <div className={css.root}>
            <div className={css.filterHeader}>
              <button type="button" className={css.labelButton} onClick={toggleIsOpen}>
                <span className={css.labelButtonContent}>
                  <span className={css.labelWrapper}>
                    <span className={css.label}>{searchFilterLabel}</span>
                  </span>
                  <span className={css.openSign}>
                    <IconPlus isOpen={isOpen} isSelected={false} />
                  </span>
                </span>
              </button>
            </div>
            <div className={classNames(css.plain, { [css.isOpen]: isOpen })}>
              <Field
                name="keywords"
                render={renderProps => {
                  const { input } = renderProps;
                  const onSubmit = e => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleSubmit(values);
                    input.onBlur();
                  };
                  const handleClear = e => {
                    e.preventDefault();
                    e.stopPropagation();
                    input.onChange(null);
                    handleSubmit(values);
                  };
                  return (
                    <form onSubmit={onSubmit}>
                      <input
                        {...input}
                        id="keywordsFilter"
                        type="text"
                        autoComplete="off"
                        placeholder={searchFilterPlaceholder}
                        className={css.input}
                      />
                      <button type="button" className={css.clearButton} onClick={handleClear}>
                        <FormattedMessage id="FilterPlain.clear" />
                      </button>
                    </form>
                  );
                }}
              />
            </div>
          </div>
        );
      }}
    />
  );
};

SearchFilter.propTypes = {
  initialValues: func.isRequired,
};

export default SearchFilter;
